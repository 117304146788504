import $ from 'jquery';
window.jQuery = $;
import AOS from 'aos';
import 'popper.js';
import 'bootstrap';
import _ from 'lodash';
import slick from 'slick-carousel';

/**
 * @description new prototype function for checking if an element is within the viewport
 * 
 * @param {*} $ jQuery object for the window
 */
(function ($) {
  $.fn.isInViewport = function () {
    let elementTop = $(this).offset().top;
    let elementBottom = elementTop + $(this).outerHeight();
    let viewportTop = $(window).scrollTop();
    let viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
  };
});

$(document).ready(function () {
  // AOS.
  setTimeout(() => {
    $('body').addClass('loaded');
    setTimeout(() => {
      AOS.init();
    }, 500);
  }, 1000)
  // AOS.init();

  scrollWatcher();
  containerFix();
  sliders();
  runMaps();
  submenuTopFix();
  offsetHero(); 

  $(window).resize(() => {
    containerFix();
    runMaps();
    submenuTopFix();
  });

  $(window).scroll(() => {
    scrollWatcher();
  })

  // Smooth scroll.
  $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').click(function (event) {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      var offset = $('header').outerHeight();
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top - offset
        }, 1000);
      }
    }
  });

  // Burger click
  $('#burger').click(function () {
    open = !open;
    toggleMenu(open);
  });

  // Active class.
  $('header .nav [href]').each(function () {
    if (this.href == window.location.href) {
      $(this).addClass('active');
    }
  });

  // Submenu.
  $('.submenu-toggle').on('click', function () {
    var toggle = $(this);
    toggle.toggleClass('open');
    $(this).prev('.parent-item').toggleClass('open');
    var submenu = $(this).next('.submenu');
    submenu.children('ul').slideToggle('fast');
  });

  // Submenu fix.
  function submenuTopFix() {
    if($(window).width() > 991) {
      var headerHeight = $('header').outerHeight();
      var dropdown = $('.service-submenu');
      let adminbar = $('#wpadminbar');

      if(adminbar.length) {
        headerHeight += $(adminbar).outerHeight();
      }

      dropdown.css('top', headerHeight + 'px');
    }
  }

  function offsetHero() {
    let hero = $('.offset-hero, .hero');
    console.log(hero);
    let header = $('header');

    if(hero.length && header.length) {
      let height = $(header).outerHeight();
      $(hero).css('margin-top', `${height}px`);
    }
  }

  $('.modal-tile-anchor').on('click', function () {
    let id = jQuery(this).attr('id');
    let modal = jQuery(`#modal-${id}`);
    if (modal.length) {
      jQuery('html').addClass('overflow-hidden');
      jQuery(modal).addClass('d-block');
    }
  });

  $('.close').on('click', function () {
    let modal = jQuery(this).closest('.modal');
    if (modal.length) {
      jQuery('html').removeClass('overflow-hidden');
      jQuery(modal).removeClass('d-block');
    }
  });
});


/**
 * @description run on scroll event to add classs to header on scroll
 */
let scrollWatcher = () => {
  let scroll = $(window).scrollTop();
  if (scroll > 0) {
    $('header').addClass('scroll');
  } else {
    $('header').removeClass('scroll');
  }
}

/**
 * @description function for fixing floating elements or page width elements with content to the conventional container sizes
 */
let containerFix = () => {
  if ($('.container-fix').length) {
    var windowWidth = $(window).width();
    var containerWidth = ($('.container').width() + 32);
    var padding = ((windowWidth - containerWidth) / 2) + 16;
    if (windowWidth >= 992) {
      $('.container-fix').each(function () {
        if ($(this).hasClass('odd')) {
          $(this).css('padding-right', padding + 'px');
        } else if ($(this).hasClass('even')) {
          $(this).css('padding-left', padding + 'px');
        }
      });
    } else if (windowWidth >= 576 && windowWidth <= 991) {
      $('.container-fix').each(function () {
        $(this).css('padding-left', padding + 'px');
        $(this).css('padding-right', padding + 'px');
      });
    } else {
      $('.container-fix').each(function () {
        $(this).css('padding-left', '16px');
        $(this).css('padding-right', '16px');
      });
    }
  }
}

/**
 * @description initialise the slick sliders for the page
 */
let sliders = () => {
  // generic slider example
  // go to - https://kenwheeler.github.io/slick/ - for documentation
  $('.slider').slick({
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToScroll: 1,
    slidesToShow: 1
  });

  $('.service-cta-carousel').slick({
    dots: true,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 4000,
    infinite: false,
    mobileFirst: true,
    responsive: [{
        breakpoint: 991,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2
        }
      }
    ],
    slidesToScroll: 1,
    slidesToShow: 1
  });
  $('.slick-services.slick-prev').click(function () {
    $('.service-cta-carousel').slick('slickPrev');
  });
  $('.slick-services.slick-next').click(function () {
    $('.service-cta-carousel').slick('slickNext');
  });

  $('.stat-carousel, .latest-news-carousel').slick({
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    mobileFirst: true,
    infinite: true,
    responsive: [{
      breakpoint: 767,
      settings: {
        slidesToShow: 2
      }
    }],
    slidesToScroll: 1,
    slidesToShow: 1
  });

  $('.infobox-carousel').slick({
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToScroll: 1,
    slidesToShow: 1
  });
  $('.slick-infobox.slick-prev').click(function () {
    $('.infobox-carousel').slick('slickPrev');
  });
  $('.slick-infobox.slick-next').click(function () {
    $('.infobox-carousel').slick('slickNext');
  });

  // Timeline carousel.
  $('.carousel-timeline').slick({
    dots: true,
    infinite: false,
    mobileFirst: true,
    nextArrow: '<button class="slick-arrow slick-next"></button>',
    prevArrow: '<button class="slick-arrow slick-prev"></button>',
    responsive: [{
      breakpoint: 992,
      settings: {
        fade: true,
        infinite: false,
      }
    }, ],
    customPaging: function (slider, i) {
      return '<button class="tab"><span>' + $(slider.$slides[i]).children('div').children('div').attr('title') + '</span></button>';
    },
  });


  $('.cs-cta-carousel').slick({
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    mobileFirst: true,
    responsive: [{
        breakpoint: 991,
        settings: {
          slidesToShow: 5
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 3
        }
      }
    ],
    slidesToScroll: 1,
    slidesToShow: 2
  });

  $('.tile-carousel').slick({
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1
  });
  $('.slick-tile.slick-prev').click(function () {
    $('.tile-carousel').slick('slickPrev');
  });
  $('.slick-tile.slick-next').click(function () {
    $('.tile-carousel').slick('slickNext');
  });

  $('.blog-carousel').slick({
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToScroll: 1,
    slidesToShow: 1
  });
  $('.slick-blog.slick-prev').click(function () {
    $('.blog-carousel').slick('slickPrev');
  });
  $('.slick-blog.slick-next').click(function () {
    $('.blog-carousel').slick('slickNext');
  });


  $('.micro-service-carousel').slick({
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToScroll: 1,
    slidesToShow: 1
  });
  $('.slick-micro-service.slick-prev').click(function () {
    $('.micro-service-carousel').slick('slickPrev');
  });
  $('.slick-micro-service.slick-next').click(function () {
    $('.micro-service-carousel').slick('slickNext');
  });

  $('.our-services-carousel').slick({
    dots: true,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 4000,
    mobileFirst: true,
    infinite: true,
    responsive: [{
      breakpoint: 767,
      settings: {
        slidesToShow: 2
      }
    }],
    slidesToScroll: 1,
    slidesToShow: 1
  });
  $('.slick-services.slick-prev').click(function () {
    $('.our-services-carousel').slick('slickPrev');
  });
  $('.slick-services.slick-next').click(function () {
    $('.our-services-carousel').slick('slickNext');
  });


  $('.case-studies-carousel').slick({
    dots: true,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 4000,
    mobileFirst: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1
  });
  $('.slick-services.slick-prev').click(function () {
    $('.case-studies-carousel').slick('slickPrev');
  });
  $('.slick-case-studies.slick-next').click(function () {
    $('.case-studies-carousel').slick('slickNext');
  });

  // slider arrow functionality
  // $('.slick-{arrow-name}.slick-prev').click(function () {
  //   $('.{carousel-name}').slick('slickPrev');
  // });
  // $('.slick-{arrow-name}.slick-next').click(function () {
  //   $('.{carousel-name}').slick('slickNext');
  // });
}

/**
 * @description handles opening and closing of the mobile menu on the site
 * 
 * @param {*} open flag indicating whether or not the menu is in an open state
 */
var open = false;
let toggleMenu = (open) => {
  $('#burger').toggleClass('open', open);
  $('#navigation-mobile ul').toggleClass('open', open);
}

/**
 * @description centers the map on the current marker or in the center of all markers present
 * 
 * @param {*} map the JSON map object being worked on
 */
let centerMap = (map) => {
  let bounds = new google.maps.LatLngBounds();
  $.each(map.markers, function (i, marker) {
    let latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
    bounds.extend(latlng);
  });
  if (map.markers.length == 1) {
    map.setCenter(bounds.getCenter());
    map.setZoom(map.zoom);
  } else {
    map.fitBounds(bounds);
  }
}

/**
 * @description adds a new marker to the map being created
 * 
 * @param {*} $marker the jQuery HTML marker to be added
 * @param {*} map the JSON map object to be operated on
 */
let addMarker = ($marker, map) => {
  let latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));
  let icon = {
    url: '' + $marker.attr('data-icon') + '',
    scaledSize: new google.maps.Size(48, 48)
  };
  let marker = new google.maps.Marker({
    icon: icon,
    map: map,
    position: latlng,
  });
  map.markers.push(marker);
  if ($marker.html()) {
    let infowindow = new google.maps.InfoWindow({
      content: $marker.html()
    });
    google.maps.event.addListener(marker, 'click', function () {
      infowindow.open(map, marker);
    });
  }
}

/**
 * @description creates a new map at the location of the jQuery element passed in
 * 
 * @param {*} $el the jQuery element to create a map aroudn 
 */
let newMap = ($el) => {
  let $markers = $el.find('.marker');
  let args = {
    center: new google.maps.LatLng(0, 0),
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    styles: [{
        "featureType": "all",
        "elementType": "labels",
        "stylers": [{
          "visibility": "on"
        }]
      },
      {
        "featureType": "all",
        "elementType": "labels.text.fill",
        "stylers": [{
            "saturation": 36
          },
          {
            "color": "#000000"
          },
          {
            "lightness": 40
          }
        ]
      },
      {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [{
            "visibility": "on"
          },
          {
            "color": "#000000"
          },
          {
            "lightness": 16
          }
        ]
      },
      {
        "featureType": "all",
        "elementType": "labels.icon",
        "stylers": [{
          "visibility": "off"
        }]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [{
            "color": "#000000"
          },
          {
            "lightness": 20
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [{
            "color": "#000000"
          },
          {
            "lightness": 17
          },
          {
            "weight": 1.2
          }
        ]
      },
      {
        "featureType": "administrative.country",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#e5c163"
        }]
      },
      {
        "featureType": "administrative.locality",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#c4c4c4"
        }]
      },
      {
        "featureType": "administrative.neighborhood",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#e5c163"
        }]
      },
      {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [{
            "color": "#000000"
          },
          {
            "lightness": 20
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [{
            "color": "#000000"
          },
          {
            "lightness": 21
          },
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "poi.business",
        "elementType": "geometry",
        "stylers": [{
          "visibility": "on"
        }]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [{
            "color": "#e5c163"
          },
          {
            "lightness": "0"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [{
          "visibility": "off"
        }]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#ffffff"
        }]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels.text.stroke",
        "stylers": [{
          "color": "#e5c163"
        }]
      },
      {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [{
            "color": "#000000"
          },
          {
            "lightness": 18
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [{
          "color": "#575757"
        }]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#ffffff"
        }]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.text.stroke",
        "stylers": [{
          "color": "#2c2c2c"
        }]
      },
      {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [{
            "color": "#000000"
          },
          {
            "lightness": 16
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#999999"
        }]
      },
      {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [{
            "color": "#000000"
          },
          {
            "lightness": 19
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [{
            "color": "#000000"
          },
          {
            "lightness": 17
          }
        ]
      }
    ],
    zoom: 16,
  };
  let map = new google.maps.Map($el[0], args);
  map.markers = [];
  $markers.each(function () {
    addMarker($(this), map);
  });
  centerMap(map);
  return map;
}

/**
 * @description convenience function for running all code required to generate a single (or multiple) maps on a page
 */
let runMaps = () => {
  $('.map').each(function () {
    newMap($(this));
  });
}